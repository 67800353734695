import {
  PATH_ABOUT_US,
  PATH_APP_ADMIN_MAIN, PATH_APP_IMAGES, PATH_APP_RADAR,
  PATH_APP_STREAMS,
  PATH_APP_USER_PROFILE,
  PATH_ROOT, PATH_TEAM
} from "../../router/AppRouter";
import {AuthContextProps} from "react-oidc-context";
import React from "react";
import {ListItemIcon} from "@mui/material";
import {Logout, Person} from "@mui/icons-material";

export const pages = () => {
  return [
    {title: 'Home', to: PATH_ROOT},
    {title: 'About us', to: PATH_ABOUT_US},
    {title: 'Team', to: PATH_TEAM},
    {title: 'Images', to: PATH_APP_IMAGES},
    {title: 'Radar', to: PATH_APP_RADAR, feature: 'radar'},
    {title: 'Streams', to: PATH_APP_STREAMS},
    {title: 'Admin', to: PATH_APP_ADMIN_MAIN, admin: true}
  ];
}

export const settings = (auth: AuthContextProps) => [
  {
    icon: () => <ListItemIcon>
      <Person fontSize="small"/>
    </ListItemIcon>,
    title: 'Profil',
    to: PATH_APP_USER_PROFILE
  },
  {
    divider: true
  },
  // {
  //     icon: () => <ListItemIcon>
  //         <PersonAdd fontSize="small"/>
  //     </ListItemIcon>,
  //     title: 'Person hinzufügen',
  //     to: PATH_APP_USER_DASHBOARD
  // }, {
  //     divider: true
  // },
  // {
  //     icon: () => <ListItemIcon>
  //         <Settings fontSize="small"/>
  //     </ListItemIcon>,
  //     title: 'Einstellungen',
  //     to: PATH_APP_USER_DASHBOARD
  // },

  {
    icon: () => <ListItemIcon>
      <Logout fontSize="small"/>
    </ListItemIcon>,
    title: 'Logout',
    func: auth.signoutRedirect
  }
];