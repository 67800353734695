import React, {createRef, ReactNode, useRef, useState} from "react";
import {
  AlertColor,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Grid,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import {InputElementRefValidateI} from "../../inputComponents/input";
import {
  extractedFormFieldProps,
  getFormConfig,
  getFormFields,
  onSubmit,
  setFormErrors,
  setFormValue,
  useCallFunction
} from "./FormFunctions";
import {ErrorInterface, TextFieldConfigInterface} from "./Form";
import {ErrorSnackbar} from "./ErrorDisplay";
import {BootstrapDialogTitle} from "../modal/Modal";
import {AxiosResponse} from "axios";
import {ApiType} from "../../provider/useHttp";

const BasicDataFormModalModal = ({
                                   title,
                                   url,
                                   value,
                                   setValue,
                                   fieldConfig,
                                   onSuccess,
                                   onFailure,
                                   apiType,
                                   withFormData,
                                   onSubscribe,
                                   onCancel,
                                   scrollType,
                                   open,
                                   setOpen
                                 }: BasicDataFormModalPropsInterface) => {
  const [errors, setErrors] = useState<ErrorInterface>()
  const [loading, setLoading] = useState<boolean>(false);
  const [errorDisplay, setErrorDisplay] = useState<{ type: AlertColor, message: String }>()
  const elementsRef = useRef(fieldConfig.map((el) => {
    const createdRef = createRef<InputElementRefValidateI>()
    el.ref = createdRef
    return createdRef;
  }));
  const formRef = useRef<HTMLFormElement>(null);
  const onSubmitFunc = onSubmit(elementsRef, value, errors, setLoading, getFormConfig(useCallFunction(apiType, withFormData, url), onFailure, (val) => {
    onSuccess && onSuccess(val)
    setOpen(false);
  }, setValue), setValue, setErrorDisplay);
  const mapPropsToInput = extractedFormFieldProps(value, setFormErrors(errors, setErrors), setValue, setFormValue(setValue, value));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [scroll] = useState<DialogProps['scroll']>(scrollType);

  const handleClose = () => {
    if (onCancel) {
      const isAsync = onCancel instanceof Promise
      if (isAsync) {
        Promise.resolve(onCancel).then(value => {
          setOpen(false)
        })
      } else {
        onCancel();
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return <form onSubmit={onSubmitFunc} ref={formRef}>
    <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
    >
      <BootstrapDialogTitle id="scroll-dialog-title"
                            onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
        >
          <ErrorSnackbar errorDisplay={errorDisplay} onClose={() => {
            setErrorDisplay(undefined)
          }}/>
          <Grid container spacing={2}>
            {getFormFields(fieldConfig, mapPropsToInput, elementsRef, value)}
          </Grid>
          {loading && <CircularProgress/>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abrechen</Button>
        <Button type="submit"
                onClick={() => onSubmitFunc(undefined)}
                disabled={loading}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  </form>
}

interface BasicDataFormModalPropsInterface {
  url?: string
  value?: any
  setValue: (val: any) => void
  fieldConfig: Array<TextFieldConfigInterface>
  buttons?: ReactNode[]
  onSuccess?: (response: AxiosResponse<any>) => void
  onFailure?: (response: AxiosResponse<any>) => void
  apiType: ApiType
  withFormData?: boolean
  title: string
  open: boolean
  setOpen: (val: boolean) => void
  onCancel?: () => void | Promise<any>
  onSubscribe?: () => void | Promise<any>
  scrollType?: DialogProps['scroll']
}

export default BasicDataFormModalModal;