import React, {Context} from "react";
import {createTheme, PaletteMode, ThemeProvider, useMediaQuery} from "@mui/material";
import {deDE} from "@mui/x-data-grid";
import {deDE as pickersDeDE} from "@mui/x-date-pickers/locales/deDE";
import {deDE as coreDeDE} from "@mui/material/locale";

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode: mode,
    secondary: {
      main: '#9b56b5',
      light: '#CDABDA',
      dark: '#6D2B8D',
      backgroundColor: mode === 'dark' ? '#fff' : '#00000099',
    },
    primary: {
      main: '#007ebb',
      light: '#58adee',
      dark: '#58adee',
      backgroundColor: mode === 'dark' ? '#fff' : '#00000099',
    },
  },
  text: {
    primary: mode === 'dark' ? '#fff' : '#00000099',
    secondary: mode === 'dark' ? '#fff' : '#00000099'
  },
  background: {
    default: "rgba(255, 255, 255, 0.05)",
    paper: "rgba(255, 255, 255, 0.05)",
  },
  typography: {
    button: {
      root: {
        main: {
          textTransform: 'none'
        }
      }
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: -1
        }
      }
    },
  },
});

export const ColorModeContext: Context<{ toggleColorMode?: () => void }> = React.createContext({});

const MainThemeProvider = (({children}: { children: React.ReactNode }) => {
  const preferredValue = useMediaQuery('(prefers-color-scheme: dark)');
  const localStorageValue = localStorage.getItem('vibe-display-mode');
  let prefersDarkMode = false;
  if (!localStorageValue) {
    prefersDarkMode = preferredValue;
  } else if (localStorageValue === 'dark') {
    prefersDarkMode = true;
  }
  const [mode, setMode] = React.useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light');
  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          localStorage.setItem('vibe-display-mode', mode === 'light' ? 'dark' : 'light');
          setMode((prevMode: PaletteMode) =>
              prevMode === 'light' ? 'dark' : 'light',
          );
        },
      }),
      [mode],
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode), deDE, pickersDeDE, coreDeDE), [mode]);

  return <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </ColorModeContext.Provider>
})

export default MainThemeProvider;