import React from "react";
import FileUpload, {AcceptedFileType, FileUploadPropsInterface} from "./FileUpload";

export interface ImageUploadPropsInterface extends FileUploadPropsInterface {
}

const ImageUpload = (props: ImageUploadPropsInterface) => {
  return (
      <FileUpload {...{...props}} fileType={AcceptedFileType.AllImages}/>
  );
}

export default ImageUpload