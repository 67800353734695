import {FieldType, TextFieldConfigInterface} from "../form/Form";
import {URLStringValidator} from "../validationConstants/ValidationConstants";
export const inputFieldNames: Array<TextFieldConfigInterface> = [
    {
        id: "allgemein",
        title: "Allgemein",
        type: FieldType.SUB_FORM,
    },
    {
        id: "title",
        title: "Titel",
        type: FieldType.TEXT,
        required: true
    },
    {
        id: "image",
        title: "Bild",
        type: FieldType.IMAGE,
    },
    {
        id: "description",
        title: "Beschreibung",
        type: FieldType.MARKDOWN,
        required: true
    },
    {
        id: "genre",
        title: "genre",
        type: FieldType.TEXT,
    },
    {
        id: "label",
        title: "Label",
        type: FieldType.TEXT,
    },
    {
        id: "url",
        title: "url",
        type: FieldType.TEXT,
    },
    {
        id: "vibeArtist",
        title: "Vibe Künstler",
        type: FieldType.CHECK,
    },
    {
        id: "weiteres",
        title: "Details",
        type: FieldType.SUB_FORM,
    },
    {
        id: "websideUrl",
        title: "Webseite URL",
        type: FieldType.TEXT,
        validation: [URLStringValidator]
    },
    {
        id: "soundcloudUrl",
        title: "Soundcloud Url",
        type: FieldType.TEXT,
        validation: [URLStringValidator],
    },
    {
        id: "instagramUrl",
        title: "Instagram URL",
        type: FieldType.TEXT,
        validation: [URLStringValidator]
    },
    {
        id: "youTubeUrl",
        title: "YouTube Url",
        type: FieldType.TEXT,
        validation: [URLStringValidator],
    },
]

export const publicInputFieldNames: Array<TextFieldConfigInterface> = [
    {
        id: "allgemein",
        title: "Allgemein",
        type: FieldType.SUB_FORM,
    },
    {
        id: "title",
        title: "Titel",
        type: FieldType.TEXT,
        required: true
    },
    {
        id: "image",
        title: "Bild",
        type: FieldType.IMAGE,
    },
    {
        id: "description",
        title: "Beschreibung",
        type: FieldType.MARKDOWN,
        required: true
    },
    {
        id: "genre",
        title: "genre",
        type: FieldType.TEXT,
    },
    {
        id: "label",
        title: "Label",
        type: FieldType.TEXT,
    },
    {
        id: "weiteres",
        title: "Details",
        type: FieldType.SUB_FORM,
    },
    {
        id: "websideUrl",
        title: "Webseite URL",
        type: FieldType.TEXT,
        validation: [URLStringValidator]
    },
    {
        id: "soundcloudUrl",
        title: "Soundcloud Url",
        type: FieldType.TEXT,
        validation: [URLStringValidator],
    },
    {
        id: "instagramUrl",
        title: "Instagram URL",
        type: FieldType.TEXT,
        validation: [URLStringValidator]
    },
    {
        id: "youTubeUrl",
        title: "YouTube Url",
        type: FieldType.TEXT,
        validation: [URLStringValidator],
    },
]

