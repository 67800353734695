import {User} from "oidc-client-ts";
import {ImageInterface} from "../image/Image";

export interface UserContextInterface {
    user: User | null | undefined;
    jwtDecoded: JWTInterface | null | undefined;
    userProfile: UserProfileInterface | null | undefined;
    setUserProfile: (userProfile: UserProfileInterface) => void
    roles?: string | null;
    resources?: string | null;
}

export enum Membership {
    ACTIVE = "ACTIVE",
    PASSIVE = "PASSIVE",
    DEACTIVATED = "DEACTIVATED",
    NOT_ACTIVE = "NOT_ACTIVE",
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
    UNKNOWN = "UNKNOWN",
}

export interface UserProfileInterface {
    id?: number
    publicId?: string
    created?: string
    createdByEMail?: string
    updated?: string
    updatedByEMail?: string
    email?: String;
    phone?: String;
    firstname?: String;
    name?: String;
    membership?: Membership;
    birthdate?: String;
    birthplace?: String;
    gender?: Gender;

    account?: AccountInterface;

    image?: ImageInterface;

    street?: String;
    streetNumber?: String;
    city?: String;
    bic?: String;
    addressAdd?: String;

    [other: string]: String | AccountInterface | Number | Gender | ImageInterface | undefined;
}

export interface AccountInterface {
    id?: number
    publicId?: string
    created?: string
    createdByEMail?: string
    updated?: string
    updatedByEMail?: string
    keycloakId: String;
    email: String;
    firstname: String;
    name: String;
    username: String;
}

export interface JWTInterface {
    email: String;
    given_name: String;
    preferred_username: String;
    family_name: String;
    sid: String
}