import React, {forwardRef, useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {InputElementRefValidateI, InputI} from "./input";
import {TextField} from "@mui/material";
import useGet from "../provider/useGet";
import {ApiType} from "../provider/useHttp";
import {TextFieldCreateConfigInterface} from "../components/form/Form";
import Button from "@mui/material/Button";
import BasicDataFormModalModal from "../components/form/BasicDataFormModal";
import useUser from "../provider/useUser";

export interface AsyncSearchfieldPropsInterface extends InputI {
  url: string
  apiType: ApiType
  optionRender?: (value: any) => string
  create?: TextFieldCreateConfigInterface
}

const AnychSearchfield = forwardRef(({
                                       title,
                                       id,
                                       value,
                                       setValue,
                                       optionRender,
                                       url,
                                       apiType,
                                       create
                                     }: AsyncSearchfieldPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
  const paramKey = "title";
  const user = useUser();
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [searchfield, setSearchfield] = useState<string>("")
  const getRequest = useGet<any, any>({apiType: apiType, url, user});

  useEffect(() => {
    let active = true;
    if (getRequest.paramName !== paramKey) {
      getRequest.setParamName(paramKey)
    }

    getRequest.setId(searchfield)

    return () => {
      active = false;
    };
  }, [getRequest, searchfield]);

  const noOption = create &&
      <Button onClick={_event => setModalOpen(true)}>
        {title} Nicht gefunden? Erstellen sie eine {title} neu!
      </Button>

  const noOptionModal = create &&
      <BasicDataFormModalModal title={`${title} erstellen`}
                               apiType={create.apiType}
                               url={create.createUrl}
                               fieldConfig={create.createFormFields}
                               setValue={setValue}
                               value={value}
                               open={modalOpen}
                               withFormData={false}
                               setOpen={setModalOpen}
      />

  return (<>
        {noOptionModal}
        <Autocomplete
            id={id.toString()}
            open={open}
            noOptionsText={noOption}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => {
              if (!!optionRender) {
                return optionRender(option)
              } else {
                return option.title
              }
            }}
            options={getRequest?.value || []}
            loading={getRequest.loading}
            value={value}
            onChange={(event, value1) => {
              setValue(value1)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                    onChange={event => {
                      setSearchfield(event.target.value)
                    }}
                    label={title.toString()}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <React.Fragment>
                            {getRequest.loading ?
                                <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                      ),
                    }}
                />
            )}
        />
      </>
  );
})

export default AnychSearchfield;