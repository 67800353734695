import React, {forwardRef, useState} from 'react';
import {InputElementRefValidateI} from "./input";
import AsynchSearchfield, {AsyncSearchfieldPropsInterface} from "./AsynchSearchfield";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import {ArtistsInterface} from "../components/artists/Artist";

export interface ArtistsListInputPropsInterface extends AsyncSearchfieldPropsInterface {
  value: any[];
  setValue(val: any[]): void
}

const ArtistsListInput = forwardRef((props: ArtistsListInputPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
  const {value, ...asyncSearchFieldProps} = props;
  const newVal = {title:''};
  const empty = [newVal]
  const [renderedValues, setRenderedValues] = useState(value || empty);

  function updateValues(newValue: any[]) {
    props.setValue(newValue);
    setRenderedValues(newValue)
  }
  const createHandler = () => {
    let newValue = renderedValues.map(value1 => {return {...value1}});
    newValue.push(newVal)
    updateValues(newValue);
  };
  const deleteHandler = (index: any) => {
    if(value && value.length){
      let newValue = value.map(value1 => {return {...value1}});
      newValue.splice(index,1);
      updateValues(newValue);
    }
  };
  const setValueOnArray = (artist: ArtistsInterface, index: number) => {
    let newValue = renderedValues.map(value1 => {return {...value1}});
    newValue[index] = artist;
    updateValues(newValue);
  }

  return (
      <Grid container spacing={2}>
        {renderedValues.map((item, index) => (
            <Grid item xs={12} lg={6} key={`multi-value-${index}`}>
              <AsynchSearchfield {...asyncSearchFieldProps} value={item}
                                 setValue={(artists) => setValueOnArray(artists, index)}/>
              <Button onClick={() => deleteHandler(item)}>Artist löschen</Button>
            </Grid>
        ))}
        {renderedValues.length % 2 !== 0 && <Grid item xs={12} lg={6} key={`multi-value-create-empty`}/>}
        <Grid item xs={12} lg={6} key={`multi-value-create`}>
          <Button onClick={createHandler}>Artist hinzufügen</Button>
        </Grid>
      </Grid>
  );
});

export default ArtistsListInput;