import * as React from "react";
import {ReactNode, useState} from "react";
import {AxiosResponse} from "axios";
import BasicDataForm from "../form/BasicDataForm";
import {ApiType} from "../../provider/useHttp";
import {EventInterface} from "./Event";
import {inputFieldNamesAdmin, inputFieldNamesPublic} from "./EventFormConstants";

export interface EventFormPropsInterface {
  buttons?: ReactNode[]
  onSuccess?: (response: AxiosResponse<EventInterface>) => void
  onFailure?: (response: AxiosResponse<EventInterface>) => void
  apiType: ApiType
}

const EventForm = ({
                     buttons,
                     onSuccess,
                     onFailure,
                     apiType
                   }: EventFormPropsInterface) => {

  const [event, setEvent] = useState<EventInterface>()

  return (<BasicDataForm
      url="/event"
      value={event}
      setValue={setEvent}
      fieldConfig={apiType === ApiType.PUBLIC ? inputFieldNamesPublic : inputFieldNamesAdmin}
      buttons={buttons}
      onSuccess={onSuccess}
      onFailure={onFailure}
      apiType={ApiType.PUBLIC}
  />)
}


export default EventForm;