import {useEffect, useMemo, useState} from "react";
import useHttp, {ApiCallType, findByIdParams, HttpInterface} from "./useHttp";
import {UserContextInterface} from "../components/userProfile/UserProfile";

export interface GetInterface<I> extends HttpInterface<I> {
  id: string | undefined
  setId: (val: string) => void
  paramName: string
  noValue?: boolean
  setParamName: (val: string) => void
}

export interface GetApiCallTypeInterface<T, U> extends ApiCallType<T, U> {
  user: UserContextInterface | null
}


type GetFunctionType = <T, I>({
                                apiType,
                                url,
                                noValue,
                                config,
                              }: GetApiCallTypeInterface<T, I>) => GetInterface<T>;


const useGet: GetFunctionType = <I, U>({
                                         apiType,
                                         url,
                                         noValue,
                                         config,
                                         axiosConfig,
                                         user
                                       }: GetApiCallTypeInterface<I, U>
) => {
  const [id, setId] = useState<string>()
  const [requestedId, setRequestedId] = useState<string>()
  const [paramName, setParamName] = useState("id")
  const [value, setValue] = useState<I>()
  const [loading, setLoading] = useState<boolean>(false)
  const http = useHttp(user?.user?.access_token, apiType);
  const params = config?.params ? config.params : id ? findByIdParams(id, paramName) : undefined

  useEffect(() => {
    const getCallFunction = () => {
      if (params || noValue) {
        const requestUrl = url + (params ? params : "");
        return http?.get(requestUrl, axiosConfig)
      }
    }

    const callFunction = getCallFunction()
    // @ts-ignore
    if (callFunction && !loading && ((requestedId !== params) || (noValue && !value))) {
      setLoading(true)
      setRequestedId(params)
      if (config?.onSubmit) {
        config.onSubmit()
      }
      callFunction.then(response => {
        setValue(response.data)
        if (config?.onSuccess) {
          config.onSuccess(response)
        }
      }).catch(reason => {
        if (config?.onFailure) {
          config.onFailure(reason)
        }
        console.error("error", reason)
      }).finally(() => {
            setLoading(false)
          }
      )
    }
  }, [id, loading, config, http, url, value, axiosConfig])

  return useMemo(() => {
        return {
          id,
          setId,
          paramName,
          setParamName,
          setValue,
          value,
          loading,
          setLoading,
          noValue,
          params,
          requestedId
        }
      },
      [
        id,
        setId,
        setValue,
        paramName,
        setParamName,
        loading,
        setLoading,
        value,
        noValue,
        params,
        requestedId
      ]
  )
}

export default useGet