import "./fileUpload.scss"
import React, {useEffect} from 'react'
import {FilledInput, InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {InputI} from "./input";
import ImageCrop from "../components/crop/ImageCrop";
import blankAvatar from "../assets/blank-avatar.png"

export const AcceptedFileType = {
  Text: '.txt',
  Gif: '.gif',
  Jpeg: '.jpg',
  Png: '.png',
  Doc: '.doc',
  Pdf: '.pdf',
  Mp3: '.mp3',
  AllImages: 'image/*',
  AllVideos: 'video/*',
  AllAudios: 'audio/*',
};

export interface FileUploadPropsInterface extends InputI {
  fileType?: string | Array<string>
  fileName?: string
  asCrop?: boolean
  canvas?: HTMLCanvasElement
  setCanvas?: (elem: HTMLCanvasElement) => void
  data?: string
}

const FileUpload = ({
                      fileType,
                      id,
                      title,
                      value,
                      setValue,
                      fileName,
                      validation,
                      setFormError,
                      asCrop,
                      canvas,
                      setCanvas,
                      required,
                      data
                    }: FileUploadPropsInterface) => {
  const fileRef = React.useRef<any>();
  const acceptedFormats =
      typeof fileType === 'string'
          ? fileType
          : Array.isArray(fileType)
              ? fileType?.join(',')
              : AcceptedFileType.Text;

  const [selectedFile, setSelectedFile] = React.useState<File>();

  const previewSrc = value && value.data && !selectedFile ? `data:${fileType};base64, ${value?.data}`
      : selectedFile ? URL.createObjectURL(selectedFile) : undefined

  useEffect(() => {
    if (data && (!value || !value.data)) {
      const image = {
        contentType: fileType,
        data: data,
        filename: fileName,
        title: title
      }

      setValue(image)
    }
  }, []);

  const handleFileSelect = async (e: any) => {
    let image = e.currentTarget.files[0];
    setSelectedFile(image)
    setValue(image)
  }

  const onClear = () => {
    setSelectedFile(undefined);
    setValue(undefined)
  };

  const setCanvasOverride = (element: HTMLCanvasElement) => {
    if (asCrop && setCanvas) {
      setCanvas(element)
    }
  }
  const src = previewSrc ? previewSrc : asCrop ? blankAvatar : undefined

  return (
      <>

        <div className="img-preview-wrapper" onClick={() => {
          if (!previewSrc) {
            fileRef.current?.click()
          }
        }}>
          {asCrop && src && src !== 'undefined' ?
              <ImageCrop src={src}
                         setSrc={setCanvasOverride}
                         canvas={canvas}
                         style={{
                           maxWidth: '100%',
                           height: 'auto'
                         }}/>
              :
              previewSrc && <img src={previewSrc}
                                 alt="preview"
                                 style={{
                                   display: 'block',
                                   maxWidth: '100%',
                                   maxHeight: '25em',
                                   marginLeft: 'auto',
                                   marginRight: 'auto',
                                 }}
              />
          }
        </div>
        <input
            id={`file-input-${id}`}
            key={`file-input-${id}`}
            ref={fileRef}
            hidden
            type="file"
            accept={acceptedFormats}
            onChange={handleFileSelect}
        />
        <FilledInput id={`file-input-input-${id}`}
                     title={title.toString()}
                     fullWidth
                     endAdornment={
                       <InputAdornment position="end">
                         <IconButton
                             aria-label="toggle password visibility"
                             onClick={onClear}
                             edge="end"
                         >
                           <DisabledByDefaultIcon/>
                         </IconButton>
                       </InputAdornment>
                     }
                     value={selectedFile?.name || fileName}
                     onClick={() => fileRef.current?.click()}
        />
      </>
  );
}

export default FileUpload;
