import Form, {TextFieldConfigInterface} from "../form/Form";
import * as React from "react";
import {ReactNode} from "react";
import {ApiType} from "../../provider/useHttp";
import {AxiosResponse} from "axios";
import {useCallFunction, getFormConfig} from "./FormFunctions";

export interface BasicDataFormPropsInterface {
  url: string
  value?: any
  setValue: (val: any) => void
  fieldConfig: Array<TextFieldConfigInterface>
  buttons?: ReactNode[]
  onSuccess?: (response: AxiosResponse<any>) => void
  onFailure?: (response: AxiosResponse<any>) => void
  apiType: ApiType
  withFormData?: boolean
}

const BasicDataForm = ({
                         url,
                         value,
                         setValue,
                         fieldConfig,
                         buttons,
                         onSuccess,
                         onFailure,
                         apiType,
                         withFormData
                       }: BasicDataFormPropsInterface) => {
  const callFunction = useCallFunction(apiType, withFormData, url);
  return <Form
      formConfig={getFormConfig(callFunction, onFailure, onSuccess, setValue)}
      buttons={buttons}
      fieldConfig={fieldConfig}
      value={value}
      setValue={setValue}
      withFormData={withFormData}
  />
}

export default BasicDataForm;