import "./side.scss"
import * as React from 'react';
import {useEffect} from 'react';
import useGet from "../../provider/useGet";
import {ApiType} from "../../provider/useHttp";
import {Card, CardContent, useTheme} from "@mui/material";
import {useParams} from "react-router";
import Body from "./Body";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {SideInterface} from "../side/Side";
import LoadingSide from "../../views/loading/LoadingSide";
import ReactMarkdownRender from "./MarkdownRender";
import useUser from "../../provider/useUser";

export interface SideViewProps {
    title?: string,
    children?: any
    sideUrl?: string
    fallbackMarkdown?: string
    noCall?: boolean
}

const getMarkdownRender = (markdown: string) =>
    <Card>
        <CardContent className="markdown-render" style={{padding: '44px'}}>
            <ReactMarkdownRender markdown={markdown}/>
        </CardContent>
    </Card>

const Side = ({title, sideUrl, fallbackMarkdown, children, noCall = false}: SideViewProps) => {
    const navigate = useNavigate();
    const {url} = useParams();
    const usedUrl = String(sideUrl || url)
    const theme = useTheme();
    const user = useUser();

    const usedUrlDefined = !noCall && usedUrl !== undefined && usedUrl !== 'undefined';

    const get = useGet<SideInterface, Number>({
        apiType: ApiType.PUBLIC,
        url: "/side",
        config: {
            onFailure: (response) => {
                navigate("/404")
            },
            params: usedUrlDefined ? "?url=" + usedUrl : undefined
        },
        user
    })

    const usedTitle = title || get.value?.title

    useEffect(() => {
        if (usedUrlDefined && !get.loading && (!get.value || get.value.url !== usedUrl)) {
            get.setId(usedUrl)
        }
    }, [get, usedUrl, usedUrlDefined])

    if (get.loading) {
        return (<LoadingSide/>)
    }

    const returnVal = (
        <div className="side">
            {!!usedTitle && (
                <>
                    <Typography variant="h3" component="h1" style={{color: theme.palette.text.primary}}>
                        {usedTitle}
                    </Typography>
                    <br/>
                </>
            )}
            <Typography className="content" component="div">
                {get.value && getMarkdownRender(get.value.markdown)}
                {!get.value && !children && !!fallbackMarkdown && getMarkdownRender(fallbackMarkdown)}
                {children}
            </Typography>
        </div>)

    if (!!url && !noCall) {
        return <Body>{returnVal}</Body>
    }

    return returnVal
}

export const failMarkdown = `Hier ist etwas schief gelaufen`

export default Side