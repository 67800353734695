import React from "react";
import MDEditor from '@uiw/react-md-editor';
import {InputI} from "./input";
import {useTheme} from "@mui/material";
import './markdownEditor.scss'

export interface MarkdownEditorPropsInterface extends InputI {
}

const MarkdownEditor = ({id, title, required, value, setValue}: MarkdownEditorPropsInterface) => {
  const theme = useTheme();
  return (
      <>
        <div>{title}</div>
        <MDEditor
            id={id.toString()}
            title={title.toString()}
            value={(value || "").toString()}
            data-color-mode={theme.palette.mode}
            lang="de-DE"
            onChange={setValue}
        />
      </>
  );
}

export default MarkdownEditor