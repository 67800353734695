import * as React from 'react';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import {TextField} from '@mui/material';
import {InputElementRefValidateI, InputI} from "./input";
import {EmptyStringValidator} from "../components/validationConstants/ValidationConstants";

export interface SelectPropsInterface extends InputI {
    selectValues: Array<SelectValueInterface>
    emptyValue?: boolean
}

export interface SelectValueInterface {
    value: string,
    title: string,
}

const Select  = forwardRef(({
                    title,
                    value,
                    setValue,
                    required,
                    validation,
                    disabled,
                    setFormError,
                    selectValues,
                    emptyValue = false
                }: SelectPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
    const EMPTY_VALUE = "#EMPTY#";
    const addEmptyValue = (selectValues: Array<SelectValueInterface>) => {
        const newSelectValues = []
        if(emptyValue){
            newSelectValues.push({value: EMPTY_VALUE, title: ""})
        }
        selectValues.forEach((val) => newSelectValues.push(val))
        return newSelectValues;
    }

    const [menuItems] = React.useState<Array<SelectValueInterface>>(addEmptyValue(selectValues))
    const [fieldValue, setFieldValue] = useState<string>(EMPTY_VALUE);
    const [isSet, setIsSet] = useState<Boolean>(false);
    const [errors, setErrors] = useState<Array<String>>([])
    const [dirty, setDirty] = useState<Boolean>(false);
    const [validated, setValidated] = useState<Boolean>(false);

    useEffect(() => {
        if (value && !isSet) {
            setIsSet(true);
            setFieldValue(value.toString());
        }
    }, [value, fieldValue, isSet])

    const validate = useCallback(() => {
        const usedValidation = validation || []
        if (required) {
            usedValidation?.unshift(EmptyStringValidator)
        }

        const newErrors: React.SetStateAction<String[] | undefined> = []
        usedValidation?.forEach(validator => {
                if (fieldValue === undefined) {
                    newErrors.push(EmptyStringValidator.message(title, ""))
                } else if (!validator.func(fieldValue)) {
                    newErrors.push(validator.message(title, fieldValue))
                }
            }
        )

        setErrors(newErrors)
        if (!!setFormError) {
            setFormError(title, newErrors);
        }
        setValidated(true);
        return newErrors.length
    }, [fieldValue, setFormError, title, required, validation])

    useImperativeHandle(ref, () => ({
        validate() {
           return validate()
        }
    }));

    useEffect(() => {
        if (dirty && !validated) {
            validate()
        }
    }, [dirty, validated, validate])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(String(event.target.value))
        if (EMPTY_VALUE === event.target.value && !emptyValue) {
            setValue(null)
        } else {
            setValue(event.target.value)
        }
        setDirty(true)
    };

    return (
        <TextField
            id={`select-${title}`}
            value={fieldValue}
            label={title}
            disabled={disabled}
            key={`filled-basic-${title}`}
            error={errors.length > 0}
            variant="filled"
            fullWidth
            select
            onChange={handleChange}
        >
            {menuItems.map(menuItems =>
                <MenuItem key={`select-key-${menuItems.value}`} value={menuItems.value}>
                    <em>{menuItems.title}</em>
                </MenuItem>)
            }
        </TextField>
    );
})

export default Select
