import * as React from 'react';
import Container from "@mui/material/Container";
import Footer from "./Footer";
import {useTheme} from "@mui/material";

const Body = ({children}: { children: any }) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.primary.main
  return (
      <>
        <Container maxWidth="xl"
                   children={children}
                   style={{
                     marginTop: "2em",
                     marginBottom: "2em",
                     minHeight: "74vh"
                   }}
        />
        <footer className="App-Footer" style={{backgroundColor}}>
          <Container maxWidth="xl">
            <Footer/>
          </Container>
        </footer>
      </>
  )
}

export default Body