import React, {useState} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const {children, onClose, ...other} = props;

  return (
      <DialogTitle sx={{m: 0, p: 2}} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
            >
              <CloseIcon/>
            </IconButton>
        ) : null}
      </DialogTitle>
  );
}

interface ModalI {
  title: string
  body: React.ReactNode
  submitDisabled?: boolean
  open: boolean
  setOpen: (value: boolean) => void
  onCancel?: () => void | Promise<any>
  onSubscribe?: () => void | Promise<any>
  scrollType?: DialogProps['scroll']
}

const Modal = ({
                 title,
                 body,
                 submitDisabled = false,
                 open,
                 setOpen,
                 onSubscribe,
                 onCancel,
                 scrollType = 'body'
               }: ModalI) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [scroll] = useState<DialogProps['scroll']>(scrollType);

  const subscribe = () => {
    if(!!onSubscribe) {
      const isAsync = onSubscribe instanceof Promise

      if (isAsync) {
        Promise.resolve(onSubscribe).then(value => {
          setOpen(false)
        })
      } else {
        onSubscribe();
        setOpen(false);
      }
    }
  }

  const handleClose = () => {
    if (onCancel) {
      const isAsync = onCancel instanceof Promise
      if (isAsync) {
        Promise.resolve(onCancel).then(value => {
          setOpen(false)
        })
      } else {
        onCancel();
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return <BootstrapDialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      scroll={scroll}
      fullWidth={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
  >
    <BootstrapDialogTitle id="scroll-dialog-title"
                          onClose={handleClose}>{title}</BootstrapDialogTitle>
    <DialogContent dividers={scroll === 'paper'}>
      <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
      >
        {body}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Abrechen</Button>
      <Button disabled={submitDisabled} onClick={subscribe}>Speichern</Button>
    </DialogActions>
  </BootstrapDialog>;
}

export default Modal