import Typography from "@mui/material/Typography";
import {CircularProgress, useTheme} from "@mui/material";
import React from "react";

const LoadingSide = () => {
  const theme = useTheme();
  return <div className="side">
    <Typography className="content" sx={{margin: '10vh 10vw', width: '100%'}}>
      <CircularProgress size={99} sx={{margin: 'auto'}}/>
      <br/>
      <br/>
      <Typography variant="h1" sx={{
        fontSize: '36px',
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.dark
      }}>
        Inhalt wird geladen!
      </Typography>
    </Typography>
  </div>;
}

export default LoadingSide;