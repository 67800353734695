import {Validator} from "validator.ts/Validator";

const validator = new Validator();

export interface ValidationInterface {
  title: String,
  func: (val: String, secondVal?: String) => Boolean
  message: (title: String, _value: String) => String
}

export const EmptyStringValidator: ValidationInterface = {
  title: 'NotEmpty',
  func: (val, _secondValue) => val && validator.isLength(val.toString(), 1),
  message: (title, _value) => `${title} darf nicht leer sein`
}

export const EMailStringValidator: ValidationInterface = {
  title: 'isNoEMail',
  func: (val, _secondValue) => !val || validator.isEmail(val.toString(), {}),
  message: (title, _value) => `${title} muss eine E-Mail Adresse sein`
}

export const URLStringValidator: ValidationInterface = {
  title: 'isNoURL',
  func: (val, _secondValue) => !val || validator.isURL(val.toString(), {}),
  message: (title, _value) => `${title} muss eine gültige URL Adresse sein`
}

export const NumberValidator: ValidationInterface = {
  title: 'isNoNumber',
  func: (val, _secondValue) => !val || validator.isNumeric(val.toString()),
  message: (title, _value) => `${title} muss eine gültige Zahl sein`
}

export const isEqualValidator: ValidationInterface = {
  title: 'isNotEqual',
  func: (val, secondVal_) => {
    return !val || val === secondVal_
  },
  message: (title, _value) => `Die eingetragene ${title} muss mit deiner übereinstimmen'`
}