import {Alert, AlertColor, Snackbar} from "@mui/material";
import React from "react";

export function ErrorSnackbar(props: {
  errorDisplay: { type: AlertColor; message: String } | undefined,
  onClose: () => void
}) {
  return <Snackbar open={!!props.errorDisplay} autoHideDuration={6000} onClose={props.onClose}>
    <Alert severity={props.errorDisplay?.type}
           sx={{width: "100%"}}>{props.errorDisplay?.message}</Alert>
  </Snackbar>;
}