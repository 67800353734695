export enum PublicState {
    IN_PROGRESS = "IN_PROGRESS",
    NOT_PUBLISHED = "NOT_PUBLISHED",
    PUBLISHED = "PUBLISHED",
    INACTIVE = "INACTIVE"
}

export interface SideInterface {
    id: number
    publicId: string
    title: string
    url: string
    status: PublicState
    markdown: string
}
