import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import Body from "../../components/content/Body";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";

const Redirect = (props: any) => {
  const navigate = useNavigate()
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/")
    }
  })

  return (<Body>
        <div className="side">
          <Typography variant="h4" component="h1">
            Du wirst angemeldet :-)
          </Typography>
          <br/>
          <span><CircularProgress/></span>
        </div>
      </Body>
  );
}

export default Redirect