import React, {createRef, ReactNode, useRef, useState} from "react";
import {AlertColor, CircularProgress, Divider, Grid} from "@mui/material";
import {ValidationInterface} from "../validationConstants/ValidationConstants";
import {SelectValueInterface} from "../../inputComponents/Select";
import Button from "@mui/material/Button";
import {ApiType, RequestInterface} from "../../provider/useHttp";
import {InputElementRefValidateI} from "../../inputComponents/input";
import {extractedFormFieldProps, getFormFields, onSubmit, setFormErrors, setFormValue} from "./FormFunctions";
import {ErrorSnackbar} from "./ErrorDisplay";

const Form = ({formConfig, fieldConfig, value, setValue, buttons, withFormData}: FormInterface) => {
  const [errors, setErrors] = useState<ErrorInterface>()
  const [loading, setLoading] = useState<boolean>(false);
  const [errorDisplay, setErrorDisplay] = useState<{ type: AlertColor, message: String }>()
  const elementsRef = useRef(fieldConfig.map((el) => {
    const createdRef = createRef<InputElementRefValidateI>()
    el.ref = createdRef
    return createdRef;
  }));

  const onSubmitFunc = onSubmit(elementsRef, value, errors, setLoading, formConfig, setValue, setErrorDisplay);
  const mapPropsToInput = extractedFormFieldProps(value, setFormErrors(errors, setErrors), setValue, setFormValue(setValue, value));

  return <form onSubmit={onSubmitFunc}>
    <ErrorSnackbar errorDisplay={errorDisplay} onClose={() => {
      setErrorDisplay(undefined)
    }}/>
    <Grid container spacing={2}>
      {getFormFields(fieldConfig, mapPropsToInput, elementsRef, value)}
    </Grid>
    <Divider style={{marginTop: '16px'}}/>
    {loading && <CircularProgress/>}
    {!loading &&
        <Button type="submit"
                key="submit-button"
                variant="contained"
                color="secondary"
                style={{float: 'right', margin: '16px 0 16px 16px'}}>
          Speichern
        </Button>
    }
    {buttons}
  </form>
}

export enum FieldType {
  TEXT = "TEXT",
  BIG_TEXT = "BIG_TEXT",
  ARTISTS_LIST = "ARTISTS_LIST",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  DATE = "DATE",
  TIME = "TIME",
  DATE_TIME = "DATE_TIME",
  CHECK = "CHECK",
  MARKDOWN = "MARKDOWN",
  EVENT_FACTS = "EVENT_FACTS",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VALUE_ASYNC_SEARCH = "VALUE_ASYNC_SEARCH",

  SUB_FORM = "SUB_FORM",
}

export interface TextFieldCreateConfigInterface {
  createUrl?: string,
  apiType: ApiType
  createFormFields: Array<TextFieldConfigInterface>
}

export interface TextFieldConfigInterface {
  id: string,
  title: string,
  ref?: React.Ref<any>,
  type: FieldType,
  list?: boolean,
  required?: boolean,
  disabled?: boolean,
  validation?: Array<ValidationInterface>,
  selectValues?: Array<SelectValueInterface>,
  emptyValue?: boolean,
  apiType?: ApiType
  url?: string
  create?: TextFieldCreateConfigInterface
}

export interface NewSubFormInterface {
  title: String;
  type: FieldType
}

export interface FormConfigInterface extends RequestInterface {
}

export interface FormInterface {
  fieldConfig: Array<TextFieldConfigInterface>
  formConfig: FormConfigInterface
  value: any,
  setValue: (val: any) => void,
  buttons?: ReactNode[]
  withFormData?: boolean
  childen?: ReactNode | ReactNode[]
}

export interface ErrorInterface {
  [x: string]: String[];
}

export default Form;