import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Checkbox as MuiCheckbox, FormControlLabel, FormGroup} from '@mui/material';
import {InputElementRefValidateI, InputI} from "./input";

export interface CheckboxPropsInterface extends InputI {
}

const Checkbox = forwardRef(({
                               title,
                               required,
                               disabled,
                               value,
                               setValue,
                               validation,
                               setFormError
                             }: CheckboxPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
  const [fieldValue, setFieldValue] = useState<boolean>(false);
  const [isSet, setIsSet] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    validate() {
      return 0
    }
  }));

    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(!fieldValue)
    setValue(!fieldValue)
    if (!dirty) {
      setDirty(true)
    }
  };

  useEffect(() => {
    if (value && !isSet) {
      setIsSet(true);
      setFieldValue(Boolean(value).valueOf());
    }
  }, [value, fieldValue, isSet])

  return <FormGroup>
    <FormControlLabel
        control={
          <MuiCheckbox title={title.toString()}
                       disabled={disabled}
                       onChange={handleChange}
                       inputProps={{'aria-label': title.toString()}}
                       checked={fieldValue}/>
        }
        label={title}/>
  </FormGroup>
})

export default Checkbox;