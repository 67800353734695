import React from 'react';
import './App.css';
import Header from "../header/Header";
import {Outlet} from 'react-router-dom';
import {Parallax} from 'react-parallax';
import image from "../../assets/background.webp"
import {useTheme} from "@mui/material";

function App() {
  const theme = useTheme();
  return (
        <div className="App" style={{backgroundColor: theme.palette.background.default}}>
            <Header />
            <Parallax
                bgImage={image}
                strength={500}
            >
                <Outlet/>
            </Parallax>
        </div>
    );
}

export default App;
