import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useAuth} from "react-oidc-context";
import {AccountCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {pages, settings} from "./headerConfig";
import useUser from "../../provider/useUser";
import {Divider, ListItem, ListItemButton, ListItemText, SwipeableDrawer, useMediaQuery, useTheme} from "@mui/material";
import {getRoles} from "../../helper/rolesMapper";
import vibeImage from "../../assets/vibe-logo-white.png"
import useGet from "../../provider/useGet";
import {ApiType} from "../../provider/useHttp";
import {EventInterface} from "../event/Event";
import useSystemState from "../../provider/useSystemState";

const Header = () => {
  const [avatarShort, setAvatarShort] = React.useState<string>("U");
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const auth = useAuth();
  const user = useUser();
  const systemState = useSystemState();
  const roles = getRoles(user);
  const navigate = useNavigate();
  const [pagesList, setPageList] = useState(pages());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const filterPages = (page: any) => {
    if(page.feature){
      return systemState.isFeatureEnabled(page.feature);
    }

    if (page.admin) {
      // @ts-ignore
      return auth.isAuthenticated && !!auth.user && roles.indexOf("ADMIN") !== -1;
    }

    if (page.member) {
      // @ts-ignore
      return auth.isAuthenticated && !!auth.user && roles.indexOf("MEMBER") !== -1;
    }
    return true;
  }
  const get = useGet<EventInterface[], any>({
    apiType: ApiType.PUBLIC,
    url: "/event/public-events",
    noValue: true,
    user
  });
  const publicEvents = get.value;

  useEffect(() => {
    if (publicEvents) {
      publicEvents
      .filter(val => !pagesList.find(value => value.title === val.title))
      .forEach(event => {
        if (event.url) {
          const copyPageList = pagesList.map(value => {
            return {...value}
          })
          copyPageList.splice(copyPageList.length - 1, 0, {
            title: event.title,
            to: '/event/' + event.url
          })
          setPageList(copyPageList)
        }
      })
    }
  }, [publicEvents, pagesList]);

  useEffect(() => {
    if (user?.userProfile?.firstname && user?.userProfile?.name) {
      const newAvatarShort = user?.userProfile?.firstname?.at(0) || "" + user?.userProfile?.name?.at(0) || ""
      if (newAvatarShort) {
        setAvatarShort(newAvatarShort)
      }
    }

  }, [user])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page?: any) => {
    if (page) {
      if (page.to) {
        navigate(page.to)
      } else {
        page.func()
      }
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {!isMobile && <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: {xs: 'none', md: 'flex'},
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
            >
              <img src={vibeImage} style={{height: "42px", margin: "8px"}}
                   alt="Logo von der Vibe-Community"/>
            </Typography>}

            {isMobile && <Box sx={{flexGrow: 1, display: 'flex'}}>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
              >
                <MenuIcon/>
              </IconButton>
              <SwipeableDrawer
                  anchor="left"
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  onOpen={handleOpenNavMenu}
              >
                {pagesList
                .filter((page: any) => filterPages(page))
                .map((page: any) => (
                    <ListItem key={page.title} disablePadding>
                      <ListItemButton onClick={() => handleCloseNavMenu(page)}>
                        <ListItemText primary={page.title}/>
                      </ListItemButton>
                    </ListItem>
                ))}
              </SwipeableDrawer>
            </Box>}
            {isMobile && <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: 'flex',
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
            >
              <img src={vibeImage} style={{height: "42px", margin: "8px"}}
                   alt="Logo von der Vibe-Community"/>
            </Typography>}

            {!isMobile && <Box sx={{flexGrow: 1, display: 'flex'}}>
              {pagesList
              .filter((page: any) => filterPages(page))
              .map((page) => (
                  <Button
                      key={page.title}
                      onClick={() => handleCloseNavMenu(page)}
                      sx={{my: 2, color: 'white', display: 'block'}}
                  >
                    {page.title}
                  </Button>
              ))}
            </Box>}
            {auth.isAuthenticated ?
                <Box sx={{flexGrow: 0}}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                      <Avatar alt={avatarShort}>{avatarShort}</Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                      sx={{mt: '45px'}}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                  >
                    {settings(auth).map((setting: any, index) => {
                      if (!setting.divider) {
                        return (<MenuItem key={setting.title}
                                          onClick={() => {
                                            if (setting.to) {
                                              navigate(setting.to)
                                            } else {
                                              setting.func()
                                            }
                                            handleCloseUserMenu()
                                          }}
                        >
                          {setting.icon && setting.icon()}
                          <Typography textAlign="center">{setting.title}</Typography>
                        </MenuItem>)
                      } else {
                        return <Divider key={`divider-key-${index}`}/>
                      }
                    })}
                  </Menu>
                </Box>
                :
                <Box sx={{flexGrow: 0}}>
                  <Button size="large"
                          color="secondary"
                          variant="contained"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => auth.signinRedirect()}
                          startIcon={<AccountCircle/>}>
                    Login
                  </Button>
                </Box>
            }
          </Toolbar>
        </Container>
      </AppBar>
  )
}
export default Header;