import AppRouter from "./router/AppRouter";
import React from "react";
import MainProvider from "./provider/MainProvider";

const ThemedApp = () => {
  return <MainProvider>
    <AppRouter/>
  </MainProvider>;
}

export default ThemedApp;