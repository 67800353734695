import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export enum ApiType {
  NONE = "NONE",
  PUBLIC = "PUBLIC",
  ADMIN = "ADMIN",
  USER = "USER",
  MEMBER = "MEMBER",
  AUTHENTICATED = "AUTHENTICATED",
}

export interface HttpInterface<I> {
  loading: boolean | undefined
  setLoading: (val: boolean) => void
  value: I | undefined
  setValue: (val: I | undefined) => void
}

export interface ApiCallType<T, U> {
  apiType: ApiType,
  url?: string,
  noValue?: boolean,
  config?: {
    onSubmit?: () => void,
    onSuccess?: (response: AxiosResponse<T, U>) => void,
    onFailure?: (response: any) => void,
    params?: string
  }
  axiosConfig?: AxiosRequestConfig
}

export interface RequestInterface {
  onSubmit: (value: any) => Promise<AxiosResponse<any, any>> | undefined,
  onSuccess: (response: any) => void,
  onFailure: (response: any) => void,
}

export interface PageableI<I> {
  content: Array<I>,
  pageable: {
    sort: {
      empty: boolean,
      sorted: boolean,
      unsorted: boolean
    },
    offset: number,
    pageSize: number,
    pageNumber: number,
    paged: boolean,
    unpaged: boolean
  },
  last: boolean,
  totalPages: number,
  totalElements: number,
  size: number,
  number: number,
  sort: {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean
  },
  first: boolean,
  numberOfElements: number,
  empty: boolean
}

export const pageParams = (pageNo: number, pageSize: number, sortBy: string, sortDir: "asc" | "desc") =>
    `?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`

export const findByIdParams = (id: string, paramName: string = "id") => `?${paramName}=${id}`

const useHttp = (token: string | undefined, apiType: ApiType = ApiType.AUTHENTICATED) => {

  if (!token && apiType !== ApiType.PUBLIC) {
    return null;
  }

  let http: AxiosInstance | undefined;

  let httpUrl = "/api"

  if (apiType === ApiType.ADMIN) {
    httpUrl = "/api-admin"
  }

  if (apiType === ApiType.PUBLIC) {
    httpUrl = "/api-public"
  }

  if (apiType === ApiType.MEMBER) {
    httpUrl = "/api-member"
  }


  http = axios.create({
    baseURL: httpUrl + "/v1"
  });

  http.interceptors.request.use(function (config) {
    if (config.headers) {
      config.headers.Accept = "application/json"
      if(token){
        config.headers.Authorization = `Bearer ${token}`

      }
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

  return http;
}

export default useHttp;