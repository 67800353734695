import {ValidationInterface} from "../validationConstants/ValidationConstants";
import React from "react";
import {InputElementRefValidateI} from "../../inputComponents/input";
import {AlertColor, Divider, Grid} from "@mui/material";
import TextField from "../../inputComponents/TextField";
import Select from "../../inputComponents/Select";
import DatePicker from "../../inputComponents/DatePicker";
import TimePicker from "../../inputComponents/TimePicker";
import DateTimePicker from "../../inputComponents/DateTimePicker";
import Checkbox from "../../inputComponents/Checkbox";
import ImageUpload from "../../inputComponents/ImageUpload";
import AsynchSearchfield from "../../inputComponents/AsynchSearchfield";
import useHttp, {ApiType} from "../../provider/useHttp";
import MarkdownEditor from "../../inputComponents/MarkdownEditor";
import {ErrorInterface, FieldType, FormConfigInterface, TextFieldConfigInterface} from "./Form";
import useUser from "../../provider/useUser";
import {AxiosResponse} from "axios";
import ArtistsListInput from "../../inputComponents/ArtistsListInput";
import {ImageInterface} from "../image/Image";
import {PublicState} from "../side/Side";

export const getFormFields = (fieldConfig: Array<TextFieldConfigInterface>,
                              mapPropsToInput: (fieldConfigValue: TextFieldConfigInterface) => {
                                setFormError: (title: String, newErrors: String[]) => void;
                                setValue: (val: any) => void;
                                disabled: boolean | undefined;
                                id: string;
                                title: string;
                                value: any;
                                required: boolean | undefined;
                                validation: Array<ValidationInterface> | undefined
                              }, elementsRef: React.MutableRefObject<React.RefObject<InputElementRefValidateI>[]>,
                              value: any) => {
  const bigInputFields = [FieldType.SUB_FORM, FieldType.MARKDOWN, FieldType.BIG_TEXT, FieldType.ARTISTS_LIST];

  return <>
    {
      fieldConfig.map((fieldConfigValue, index) => {
        if (!bigInputFields.includes(fieldConfigValue.type)) {
          return (<Grid item xs={12} lg={6} key={`filled-basic-grid-${fieldConfigValue.id}`}>
            {fieldConfigValue.type === FieldType.TEXT &&
                <TextField {...{...mapPropsToInput(fieldConfigValue)}}
                           ref={elementsRef.current[index]}
                />}
            {fieldConfigValue.type === FieldType.SELECT
                && !!fieldConfigValue.selectValues &&
                <Select {...{...mapPropsToInput(fieldConfigValue)}}
                        selectValues={fieldConfigValue.selectValues}
                        emptyValue={false}
                        ref={elementsRef.current[index]}
                />}
            {fieldConfigValue.type === FieldType.DATE &&
                <DatePicker {...{...mapPropsToInput(fieldConfigValue)}}
                            ref={elementsRef.current[index]}
                />}

            {fieldConfigValue.type === FieldType.TIME &&
                <TimePicker {...{...mapPropsToInput(fieldConfigValue)}}
                            ref={elementsRef.current[index]}
                />}

            {fieldConfigValue.type === FieldType.DATE_TIME &&
                <DateTimePicker {...{...mapPropsToInput(fieldConfigValue)}}
                                ref={elementsRef.current[index]}
                />}

            {fieldConfigValue.type === FieldType.CHECK &&
                <Checkbox {...{...mapPropsToInput(fieldConfigValue)}}
                          ref={elementsRef.current[index]}
                />}

            {fieldConfigValue.type === FieldType.IMAGE &&
                <ImageUpload {...{...mapPropsToInput(fieldConfigValue)}}
                             fileType={value?.contentType}
                             fileName={value?.filename} data={value?.data}
                />}

            {fieldConfigValue.type === FieldType.VALUE_ASYNC_SEARCH &&
                <AsynchSearchfield {...{...mapPropsToInput(fieldConfigValue)}}
                                   ref={elementsRef.current[index]}
                                   apiType={fieldConfigValue.apiType || ApiType.USER}
                                   url={fieldConfigValue.url || ""}
                                   create={fieldConfigValue.create}
                />}
            {fieldConfigValue.type === FieldType.ARTISTS_LIST &&
                <ArtistsListInput {...{...mapPropsToInput(fieldConfigValue)}}
                                  ref={elementsRef.current[index]}
                                  apiType={fieldConfigValue.apiType || ApiType.USER}
                                  url={fieldConfigValue.url || ""}
                                  create={fieldConfigValue.create}
                />}
          </Grid>)
        } else {
          return (
              <Grid item xs={12} key={`filled-newSubform-${fieldConfigValue.id}`}>
                {fieldConfigValue.type === FieldType.BIG_TEXT &&
                    <TextField {...{...mapPropsToInput(fieldConfigValue)}} multiline/>}
                {fieldConfigValue.type === FieldType.SUB_FORM && (
                    <>
                      <div
                          className="MuiTypography-root MuiTypography-body1 MuiCardHeader-subheader css-nrdprl-MuiTypography-root">
                        {fieldConfigValue.title}
                      </div>
                      <Divider/>
                    </>)}
                {fieldConfigValue.type === FieldType.MARKDOWN && (
                    <MarkdownEditor {...{...mapPropsToInput(fieldConfigValue)}}/>
                )}

                {fieldConfigValue.type === FieldType.ARTISTS_LIST &&
                    <ArtistsListInput {...{...mapPropsToInput(fieldConfigValue)}}
                                      ref={elementsRef.current[index]}
                                      apiType={fieldConfigValue.apiType || ApiType.USER}
                                      url={fieldConfigValue.url || ""}
                                      create={fieldConfigValue.create}
                    />}
              </Grid>
          )
        }
      })
    }
  </>;
}

export async function readFileData(file: File): Promise<ImageInterface> {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  const returnFile: any = {
    title: file.name,
    url: file.name,
    // @ts-ignore
    status: PublicState.PUBLISHED,
    image: {
      contentType: file.type,
      // @ts-ignore
      data: result_base64.split(",")[1],
      filename: file.name,
    }
  };

  return returnFile
}

export const extractedFormFieldProps =
    (value: any,
     setFormErrors: (title: String, newErrors: String[]) => void,
     setValue: (val: any) => void,
     setFormValue: (key: string, val: any) => void
    ) => {
      return (fieldConfigValue: TextFieldConfigInterface) => {
        return {
          id: fieldConfigValue.id,
          title: fieldConfigValue.title,
          value: value?.[fieldConfigValue.id],
          required: fieldConfigValue.required,
          disabled: fieldConfigValue.disabled,
          setFormError: setFormErrors,
          setValue: (val: any) => {
            if (fieldConfigValue.type === FieldType.IMAGE && val instanceof Blob) {
              var reader = new FileReader();
              reader.readAsDataURL(val);
              reader.onload = function () {
                const values = (reader.result as String).split(",")
                setValue({
                  ...value,
                  [fieldConfigValue.id.toString()]: {
                    contentType: val.type,
                    // @ts-ignore
                    filename: val.name,
                    data: values[1]
                  }
                })
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };

            } else {
              setFormValue(fieldConfigValue.id.toString(), val)
            }
          },
          validation: fieldConfigValue.validation,
        }
      };
    }

export const onSubmit = (elementsRef: React.MutableRefObject<React.RefObject<InputElementRefValidateI>[]>, value: any, errors: ErrorInterface | undefined, setLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, formConfig: FormConfigInterface, setValue: (val: any) => void, setErrorDisplay: (value: (((prevState: ({
  type: AlertColor;
  message: String
} | undefined)) => ({ type: AlertColor; message: String } | undefined)) | {
  type: AlertColor;
  message: String
} | undefined)) => void) => {
  return (event: React.FormEvent | undefined) => {
    event?.preventDefault()
    let errorsLength = 0;
    elementsRef.current.forEach(value1 => {
      const el = value1?.current
      if (el && el.validate) {
        errorsLength = errorsLength + el.validate()
      }
    })

    if (value
        && errorsLength === 0
        && (!errors || Object.keys(errors).length === 0)
    ) {
      setLoading(true)
      const formData = new FormData()

      Object.keys(value).forEach((element) => {
        formData.append(element, value[element])
      })

      formConfig.onSubmit(value)?.then(response => {
        formConfig.onSuccess(response)
        setValue(response.data)
        setErrorDisplay({type: "success", message: "Speichern erfolgreich!"})
      }).catch(reason => {
        formConfig.onFailure(reason)
        setErrorDisplay({type: "error", message: "Speichern fehlgeschlagen!"})
      }).finally(() => {
            setLoading(false)
          }
      )
    }
  };
}

export const setFormValue = (setValue: (val: any) => void, value: any) => {
  return (key: string, val: any) => {
    setValue({...value, [key]: val})
  };
}

export const setFormErrors = (errors: ErrorInterface | undefined,
                              setErrors: (value: (((prevState: (ErrorInterface | undefined)) => (ErrorInterface | undefined)) | ErrorInterface | undefined)) => void
) => {
  return (title: String, newErrors: String[]) => {
    if (!newErrors || !newErrors.length) {
      if (!!errors) {
        delete errors[title.toString()]
      }
    } else {
      setErrors({...errors, [title.toString()]: newErrors});
    }
  };
}

export const useCallFunction = (apiType: ApiType, withFormData: boolean | undefined, url?: string) => {
  const user = useUser()
  const http = useHttp(user?.user?.access_token, apiType);

  return (data: any) => {
    if (url === undefined) {
      return undefined;
    }

    if (!!data && !!data.id) {
      return http?.put(url, data)
    }

    return http?.post(url, data)
  };

}

export const getFormConfig = (callFunction: (data: any) => (Promise<AxiosResponse<any>> | undefined), onFailure: ((response: AxiosResponse<any>) => void) | undefined, onSuccess: ((response: AxiosResponse<any>) => void) | undefined, setValue: (val: any) => void) => {
  return {
    onSubmit: callFunction,
    onFailure: (response: AxiosResponse<any>) => {
      onFailure && onFailure(response)
    },
    onSuccess: (response: AxiosResponse<any>) => {
      onSuccess && onSuccess(response)
      setValue(response.data)
    },
  };
}