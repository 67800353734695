import {createContext, useCallback, useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import jwtDecode from 'jwt-decode';
import useHttp from "./useHttp";
import {JWTInterface, UserContextInterface, UserProfileInterface} from "../components/userProfile/UserProfile";

export const UserContext = createContext<UserContextInterface | null>(null);

const UserProvider = ({children}: { children: any }) => {
    const auth = useAuth();
    const http = useHttp(auth.user?.access_token);
    const [userProfile, setUserProfile] = useState<UserProfileInterface | null>(null);
    let token: JWTInterface | null = null

    if (auth.user?.access_token !== undefined) {
        token = jwtDecode(auth.user?.access_token)
    }

    useEffect(() => {
        if (!userProfile && token && token.email) {
            http?.post("/user-profile/by-email", {
                email: token.email,
                firstname: token.given_name,
                name: token.family_name,
                username: token.preferred_username,
                keycloakId: token.sid
            })
                .then(value => {
                    if (value.status === 200 || value.status === 201) {
                        setUserProfile(value.data);
                    }
                })
        }
    }, [userProfile, http, token])

    const getContextValue = useCallback(
        () => ({user: auth.user, jwtDecoded: token, userProfile, setUserProfile}),
        [auth, token, userProfile],
    )

    return (
        <UserContext.Provider value={getContextValue()}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;