import * as React from "react";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {TextField as MuiTextField} from "@mui/material";
import {EmptyStringValidator} from "../components/validationConstants/ValidationConstants";
import {InputElementRefValidateI, InputI} from "./input";

export interface TextFieldPropsInterface extends InputI {
  multiline?: boolean
}

const TextField = forwardRef(({
                                title,
                                value,
                                setValue,
                                required,
                                disabled,
                                validation,
                                setFormError,
                                multiline = false,
                                secondValue
                              }: TextFieldPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
  const [fieldValue, setFieldValue] = useState<String>("");
  const [isSet, setIsSet] = useState<Boolean>(false);
  const [errors, setErrors] = useState<Array<String>>([])
  const [dirty, setDirty] = useState<Boolean>(false);
  const [validated, setValidated] = useState<Boolean>(false);

  useEffect(() => {
    if (value && !isSet) {
      setIsSet(true);
      setFieldValue(value);
    }
  }, [value, fieldValue, isSet])

  const validate = useCallback(() => {
    const usedValidation = validation || []
    if (required && !usedValidation.find(validator => validator.title === EmptyStringValidator.title)) {
      usedValidation?.unshift(EmptyStringValidator)
    }

    const newErrors: React.SetStateAction<String[] | undefined> = []
    usedValidation?.forEach(validator => {
          if (secondValue) {
            if (!validator.func(fieldValue, secondValue)) {
              newErrors.push(validator.message(title, fieldValue ? fieldValue : ""))
            }
          } else {
            if (!validator.func(fieldValue)) {
              newErrors.push(validator.message(title, fieldValue ? fieldValue : ""))
            }
          }
        }
    )

    setErrors(newErrors)
    if (!!setFormError) {
      setFormError(title, newErrors);
    }
    setValidated(true);
    return newErrors.length
  }, [fieldValue, secondValue, setFormError, title, validation, required])

  useImperativeHandle(ref, () => ({
    validate() {
      return validate()
    }
  }));

  useEffect(() => {
    if (dirty && !validated) {
      validate()
    }
  }, [dirty, validated, validate])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidated(false)
    setFieldValue(event.target.value)
    setValue(event.target.value)
    if (!dirty) {
      setDirty(true)
    }
  };

  const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
    if (!dirty) {
      setDirty(true)
    }
    validate()
  };

  return <MuiTextField id={`input-${title}`}
                       label={title}
                       key={`filled-basic-${title}`}
                       fullWidth
                       disabled={disabled}
                       multiline={multiline}
                       minRows={multiline ? 5 : 1}
                       error={errors.length > 0}
                       helperText={errors}
                       variant="filled"
                       InputLabelProps={{shrink: !!fieldValue}}
                       value={fieldValue}
                       onBlur={handleBlur}
                       onChange={handleChange}
  />
})

export default TextField;