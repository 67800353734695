import {useContext, useEffect} from "react";
import {UserContext} from "./UserProvider";
import {PATH_APP_UPDATE_USER_ACCOUNT} from "../router/AppRouter";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {Membership} from "../components/userProfile/UserProfile";

const useUser = () => {
  const user = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.userProfile && user?.userProfile.membership === Membership.NOT_ACTIVE
        && location.pathname !== PATH_APP_UPDATE_USER_ACCOUNT) {
      navigate(PATH_APP_UPDATE_USER_ACCOUNT)
    }
  }, [user?.userProfile, location, navigate])

  return user;
}

export default useUser;