import * as React from "react";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {TextField} from "@mui/material";
import {DatePicker as DatePickerMui} from '@mui/x-date-pickers/DatePicker';

import {EmptyStringValidator} from "../components/validationConstants/ValidationConstants";
import {InputElementRefValidateI, InputI} from "./input";

export interface DatePickerPropsInterface extends InputI {
}

const DatePicker = forwardRef(({
                        title,
                        value,
                        setValue,
                        validation,
                        disabled,
                        required,
                        setFormError
                    }: DatePickerPropsInterface, ref: React.Ref<InputElementRefValidateI>) => {
    const [fieldValue, setFieldValue] = useState<String>("");
    const [isSet, setIsSet] = useState<Boolean>(false);
    const [errors, setErrors] = useState<Array<String>>([])
    const [dirty, setDirty] = useState<Boolean>(false);
    const [validated, setValidated] = useState<Boolean>(false);

    useEffect(() => {
        if (value && !isSet) {
            setIsSet(true);
            setFieldValue(value);
        }
    }, [value, fieldValue, isSet])

    const validate = useCallback(() => {
        const usedValidation = validation || []
        if (required) {
            usedValidation?.unshift(EmptyStringValidator)
        }
        const newErrors: React.SetStateAction<String[] | undefined> = []
        usedValidation?.forEach(validator => {
                if (fieldValue === undefined) {
                    newErrors.push(EmptyStringValidator.message(title, ""))
                } else if (!validator.func(fieldValue)) {
                    newErrors.push(validator.message(title, fieldValue))
                }
            }
        )

        setErrors(newErrors)
        if (!!setFormError) {
            setFormError(title, newErrors);
        }
        setValidated(true);
        return newErrors.length
    }, [fieldValue, setFormError, title, required, validation])

    useImperativeHandle(ref, () => ({
        validate() {
            return validate()
        }
    }));

    useEffect(() => {
        if (dirty && !validated) {
            validate()
        }
    }, [dirty, validated, validate])


    const handleChange = (newValue: any | null) => {
        if (!!newValue) {
            setValidated(false)
            setFieldValue(newValue)
            setValue(newValue)
            if (!dirty) {
                setDirty(true)
            }
        }
    };

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!dirty) {
            setDirty(true)
        }
        validate()
    };

    return <DatePickerMui label={title}
                          key={`filled-basic-${title}`}
                          value={fieldValue}
                          disabled={disabled}
                          onChange={handleChange}
                          renderInput={(params) =>
                              <TextField
                                  {...params}
                                  fullWidth
                                  id={`input-${title}`}
                                  error={errors.length > 0}
                                  helperText={errors}
                                  variant="filled"
                                  InputLabelProps={{shrink: !!fieldValue}}
                                  onBlur={handleBlur}
                              />}
    />
})

export default DatePicker;