import React from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AuthProvider} from "react-oidc-context";
import AuthConfig from "../config/AuthConfig";
import UserProvider from "./UserProvider";
import MainThemeProvider from "./MainThemeProvider";
import MainStateProvider from "./SystemStateProvider";

const MainProvider = (({children}: { children: React.ReactNode }) => {
  return <MainThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <MainStateProvider>
        <AuthProvider {...AuthConfig}>
          <UserProvider>
            {children}
          </UserProvider>
        </AuthProvider>
      </MainStateProvider>
    </LocalizationProvider>
  </MainThemeProvider>
})

export default MainProvider;