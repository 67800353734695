import {FieldType, TextFieldConfigInterface} from "../form/Form";
import {URLStringValidator} from "../validationConstants/ValidationConstants";
import {inputPublicStateValues} from "../side/SideFormConstants";
import {ApiType} from "../../provider/useHttp";
import {publicInputFieldNames as artistPublicInputFieldNames, inputFieldNames as artistInputFieldNames} from "../artists/ArtistsFormConstants";

export const locationInputFieldNames: Array<TextFieldConfigInterface> = [
  {
    id: "title",
    title: "Titel",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "description",
    title: "Beschreibung",
    type: FieldType.MARKDOWN,
    required: true
  },
  {
    id: "street",
    title: "Straße",
    type: FieldType.TEXT,
  },
  {
    id: "streetNumber",
    title: "Nummer",
    type: FieldType.TEXT,
  },
  {
    id: "bic",
    title: "PLZ",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "city",
    title: "Stadt",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "addressAdd",
    title: "Addresszusatz",
    type: FieldType.TEXT,
  }]


const inputFieldNames = (apiType: ApiType) => ([
  {
    id: "allgemein",
    title: "Allgemein",
    type: FieldType.SUB_FORM,
  },
  {
    id: "title",
    title: "Titel",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "image",
    title: "Bild",
    type: FieldType.IMAGE,
  },
  {
    id: "description",
    title: "Beschreibung",
    type: FieldType.MARKDOWN,
    required: true
  },
  {
    id: "facts",
    title: "Facts",
    type: FieldType.MARKDOWN,
    required: true,
  },
  {
    id: "artists",
    title: "Artists",
    list: true,
    type: FieldType.ARTISTS_LIST,
    required: true,
    url: "/artist/select",
    apiType: ApiType.PUBLIC,
    create: {
      createUrl: "/artist",
      apiType: apiType,
      createFormFields: apiType === ApiType.PUBLIC ? artistPublicInputFieldNames : artistInputFieldNames,
    }
  },
  {
    id: "zeitpunkt",
    title: "Zeitpunkt",
    type: FieldType.SUB_FORM,
  },
  {
    id: "startDateTime",
    title: "Start",
    type: FieldType.DATE_TIME,
    required: true
  },
  {
    id: "endDateTime",
    title: "Ende",
    type: FieldType.DATE_TIME,
    required: true
  },
  {
    id: "ort",
    title: "Ort",
    type: FieldType.SUB_FORM,
  },
  {
    id: "location",
    title: "Veranstaltungsort",
    type: FieldType.VALUE_ASYNC_SEARCH,
    url: "/location/select",
    apiType: ApiType.PUBLIC,
    create: {
      createUrl: "/location",
      apiType: apiType,
      createFormFields: locationInputFieldNames
    }
  },
  {
    id: "weiteres",
    title: "Details",
    type: FieldType.SUB_FORM,
  },
  {
    id: "ticketUrl",
    title: "Ticket URL",
    type: FieldType.TEXT,
    validation: [URLStringValidator]
  },
  {
    id: "onlineEvent",
    title: "Online Event",
    type: FieldType.CHECK,
  },
  {
    id: "eventUrl",
    title: "Event Url",
    type: FieldType.TEXT,
    validation: [URLStringValidator],
  },
]);

export const inputFieldNamesAdmin = inputFieldNames(ApiType.ADMIN)
export const inputFieldNamesPublic = inputFieldNames(ApiType.PUBLIC)

export const adminInputFieldNames: Array<TextFieldConfigInterface> = [
  ...inputFieldNamesAdmin
  , {
    id: "status",
    title: "Status",
    type: FieldType.SELECT,
    selectValues: inputPublicStateValues,
    emptyValue: false,
    required: true
  },
  {
    id: "url",
    title: "Interne Url",
    type: FieldType.TEXT,
  },
  {
    id: "vibeEvent",
    title: "Vibe Event",
    type: FieldType.CHECK,
  },
]

