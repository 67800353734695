import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {useTheme} from "@mui/material";
import {ColorModeContext} from "../provider/MainThemeProvider";

const DarkModeSwitch = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
      <Box
          sx={{
            paddingLeft: 0,
            paddingTop: '14px',
            fontWeight: 'bold',
            cursor: 'pointer'
          }}
          onClick={colorMode.toggleColorMode}
      >
        <span>{theme.palette.mode === 'dark' ? 'Light' : 'Dark'} Mode</span>
        <IconButton sx={{ml: 1}} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
        </IconButton>
      </Box>
  );
}

export default DarkModeSwitch;