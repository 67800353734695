import React from "react";
import Side from "../../components/content/Side";
import {Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import EventForm from "../../components/event/EventForm";
import {ApiType} from "../../provider/useHttp";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

enum PublicEventState {
  CREATE = "CREATE",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}


const PublicEventForm = () => {
  const navigate = useNavigate()
  const [state, setState] = React.useState(PublicEventState.CREATE)

  let header = "Erstellt ein Event, welches in unserem Radar nicht fehlen darf!"
  let content = "Bei Fragen melden euch über unser Kontakt Formular."

  if (state === PublicEventState.SUCCESS) {
    header = "Deine Anfrage war erfolgreich :-)";
    content = "Wir werden die Daten in kürze überprüfen und freigeben, wenn die eingegeben Daten unseren Richtlinien entsprechen.";
  }

  if (state === PublicEventState.FAILED) {
    header = "Uuups es ist wohl etwas schief gelaufen :(";
    content = "Wollen SIe es noch einmal versuchen?";
  }

  return (
      <Side title="Event erstellen 📇">
        <Card>
          <CardContent>
            <Typography component="h2" variant="h3">
              {header}
            </Typography>
            <Typography component="p">
              {content}
            </Typography>
            <br/>
            {state === PublicEventState.CREATE && (
                <EventForm
                    apiType={ApiType.PUBLIC}
                    onSuccess={response => setState(PublicEventState.SUCCESS)}
                    onFailure={response => setState(PublicEventState.FAILED)}
                />
            )}
          </CardContent>
          {state !== PublicEventState.CREATE && (
              <CardActions>
                <Button onClick={() => navigate(-1)}>Zurück</Button>
              </CardActions>
          )}
        </Card>
      </Side>
  );
}

export default PublicEventForm;