import {FieldType, TextFieldConfigInterface} from "../form/Form";

export const inputPublicStateValues = [{
  title: "In Bearbeitung",
  value: "IN_PROGRESS",
}, {
  title: "Veröffentlicht",
  value: "PUBLISHED",
}, {
  title: "Nicht Veröffentlicht",
  value: "NOT_PUBLISHED",
}, {
  title: "Deaktiviert",
  value: "INACTIVE",
}]

export const inputFieldNames: Array<TextFieldConfigInterface> = [
  {
    id: "title",
    title: "Titel",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "url",
    title: "URL",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "status",
    title: "Status",
    type: FieldType.SELECT,
    selectValues: inputPublicStateValues,
    emptyValue: false,
    required: true
  },
  {
    id: "markdown",
    title: "Markdown",
    type: FieldType.MARKDOWN,
    required: true
  }
]