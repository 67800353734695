import React, {useRef} from 'react';
import Cropper, {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCrop = ({src, setSrc, canvas, style}: {
  src: any,
  setSrc: (val: HTMLCanvasElement) => void,
  canvas?: HTMLCanvasElement
  style: any
},) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const isEqual = (cropper: HTMLCanvasElement) => {
    return cropper?.height === canvas?.height
        && cropper?.width === canvas?.width
  }

  const getCroppedData = () => {
    if (canvas) {
      return {
        width: canvas.width,
        height: canvas.height,
      }
    }
    return {}
  }

  const getCropData = () => {
    if (cropperRef) {
      const cropper = cropperRef.current?.cropper
      if (cropper) {
        const c = cropper.getCroppedCanvas()
        if (!isEqual(c)) {
          setSrc(c)
          return c;
        }
      }
    }
  }

  return (<Cropper
          src={src}
          style={style}
          ref={cropperRef}
          initialAspectRatio={4 / 3}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          data={getCroppedData()}
          guides={true}
          cropend={getCropData}
          checkOrientation={true}
      />
  )
}
export default ImageCrop;