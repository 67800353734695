import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import App from '../components/app/App';
import {useAuth} from "react-oidc-context";
import Redirect from "../views/redirect/Redirect";
import Body from "../components/content/Body";
import {CircularProgress} from "@mui/material";
import {getRoles} from "../helper/rolesMapper";
import useUser from "../provider/useUser";
import PublicEventForm from "../views/event/PublicEventForm";
import useSystemState from "../provider/useSystemState";

const Error = React.lazy(() => import("../views/error/Error"))
const Stream = React.lazy(() => import("../views/stream/Stream"))
const Home = React.lazy(() => import("../views/home/Home"))
const Ticket = React.lazy(() => import("../views/ticket/Ticket"))
const Radar = React.lazy(() => import("../views/radar/Radar"))
// const PublicEventForm = React.lazy(() => import("../views/event/PublicEventForm"))
const Event = React.lazy(() => import("../views/event/Event"))

const UserDashboard = React.lazy(() => import("../views/settings/UserDashbaord"))
const UserAccount = React.lazy(() => import("../views/settings/UserAccount"))
const UserProfile = React.lazy(() => import("../views/settings/UserProfile"))
const UpdateUserAccount = React.lazy(() => import("../views/settings/UpdateUserAccount"))
const Side = React.lazy(() => import("../components/content/Side"))
const Admin = React.lazy(() => import("../views/admin/Admin"))
const Contact = React.lazy(() => import("../views/contact/Contact"))
const CommentActivation = React.lazy(() => import("../views/comment/CommentActivation"))
const Images = React.lazy(() => import("../views/gallery/ImageGalleriesView"))
const Gallery = React.lazy(() => import("../views/gallery/ImageGalleryView"))

// const VibeGoesKuba =  React.lazy(() => import("../views/events/VibeGoesKuba"))

const Privacy = React.lazy(() => import("../views/privacy/Privacy"))
const Conditions = React.lazy(() => import("../views/conditions/Conditions"))

const Imprint = React.lazy(() => import("../views/imprint/Imprint"))
const AboutUs = React.lazy(() => import("../views/aboutus/AboutUs"))
const Team = React.lazy(() => import("../views/team/Team"))

export const PATH_ROOT: string = '/';

export const PATH_TICKET: string = '/ticket';
export const PATH_ABOUT_US: string = '/about-us';
export const PATH_TEAM: string = '/team';

export const REDIRECT_ROUTE: string = '/app';
export const PATH_APP_STREAMS: string = '/streams';
export const PATH_APP_IMAGES: string = '/images';
export const PATH_APP_GALLERY: string = '/gallery';
export const PATH_APP_RADAR: string = '/radar';
export const PATH_APP_EVENT: string = '/event';
export const PATH_APP_CONTACT: string = '/contact';
export const PATH_APP_PRIVACY: string = '/privacy';
export const PATH_APP_IMPRINT: string = '/imprint';
export const PATH_APP_CONDITIONS: string = '/conditions';
export const PATH_APP_COMMENT_ACTIVATE: string = '/comment-activate/:key';

export const PATH_APP_SETTINGS: string = '/settings';
export const PATH_APP_USER_PROFILE: string = PATH_APP_SETTINGS + '/profile';
export const PATH_APP_USER_ACCOUNT: string = PATH_APP_SETTINGS + '/account';
export const PATH_APP_UPDATE_USER_ACCOUNT: string = PATH_APP_SETTINGS + '/update-account';
export const PATH_APP_USER_DASHBOARD: string = PATH_APP_SETTINGS + '/dashboard';

export const PATH_APP_ADMIN_MAIN: string = '/admin';

const PrivateRoute = ({
                        body = false,
                        admin = false,
                        member = false
                      }: { body?: boolean, admin?: boolean, member?: boolean }) => {
  const auth = useAuth()
  const user = useUser();
  const navigate = useNavigate()
  let returnVal = <span><CircularProgress/></span>
  const roles = getRoles(user);


  const isMemberNeeded = () => {
    // @ts-ignore
    return !(member && aroles.indexOf("MEMBER") === -1);
  }

  const isAdminIfNeeded = () => {
    // @ts-ignore
    return !(admin && roles.indexOf("ADMIN") === -1);
  }

  if (auth.isAuthenticated
      && isAdminIfNeeded()
      && isMemberNeeded()) {
    returnVal = <Outlet/>
  } else {
    navigate(PATH_ROOT)
  }

  if (body) {
    return <Body>
      {returnVal}
    </Body>
  }

  return returnVal
}

const getSuspense = (component: React.ReactNode, withoutBody = false) => {
  const suspense = <React.Suspense fallback={<><CircularProgress/></>}>
    {component}
  </React.Suspense>;

  if (withoutBody) {
    return suspense
  }

  return <Body>
    {suspense}
  </Body>
}


const AppRouter = () => {
  const systemState = useSystemState();
  const radarEnabled = systemState.isFeatureEnabled('radar')
  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path={PATH_ROOT} element={getSuspense(<App/>, true)}>

            {/*PUBLIC ROUTES - SIDE REDIRECTS*/}
            <Route index element={getSuspense(<Home/>)}/>
            <Route path={PATH_ABOUT_US} element={getSuspense(<AboutUs/>)}/>
            <Route path={PATH_TEAM} element={getSuspense(<Team/>)}/>
            <Route path={PATH_TICKET} element={getSuspense(<Ticket/>)}/>

            {/*PUBLIC ROUTES*/}
            <Route path={PATH_APP_IMAGES} element={getSuspense(<Images/>)}/>
            <Route path={PATH_APP_GALLERY + "/:url"} element={getSuspense(<Gallery/>)}/>
            <Route path={PATH_APP_STREAMS} element={getSuspense(<Stream/>)}/>
            {radarEnabled && <Route path={PATH_APP_RADAR} element={getSuspense(<Radar/>)}/>}
            <Route path={PATH_APP_CONTACT} element={getSuspense(<Contact/>)}/>

            <Route path={'/event/:url'} element={getSuspense(<Event/>)}/>

            {/*TODO RADAR*/}
            <Route path={PATH_APP_EVENT} element={getSuspense(<PublicEventForm/>)}/>
            <Route path={'/event/:url'} element={getSuspense(<Event/>)}/>

            {/*PUBLIC ROUTES - STATIC CONTENT*/}
            <Route path={PATH_APP_PRIVACY} element={getSuspense(<Privacy/>)}/>
            <Route path={PATH_APP_IMPRINT} element={getSuspense(<Imprint/>)}/>
            <Route path={PATH_APP_CONDITIONS} element={getSuspense(<Conditions/>)}/>

            <Route path={REDIRECT_ROUTE} element={<Redirect/>}/>
            <Route path={"/side/:url"} element={getSuspense(<Side/>)}/>
            <Route path={PATH_APP_COMMENT_ACTIVATE} element={getSuspense(<CommentActivation/>)}/>
            {/*ADMIN ROUTES*/}
            <Route path={PATH_APP_ADMIN_MAIN} element={<PrivateRoute body admin/>}>
              <Route index element={getSuspense(<Admin/>, true)}/>
            </Route>
            {/*USER ROUTES*/}
            <Route path={PATH_APP_USER_PROFILE} element={<PrivateRoute body/>}>
              <Route index element={getSuspense(<UserProfile/>, true)}/>
            </Route>
            <Route path={PATH_APP_USER_ACCOUNT} element={<PrivateRoute body/>}>
              <Route index element={getSuspense(<UserAccount/>, true)}/>
            </Route>
            <Route path={PATH_APP_UPDATE_USER_ACCOUNT} element={<PrivateRoute body/>}>
              <Route index element={getSuspense(<UpdateUserAccount/>, true)}/>
            </Route>
            <Route path={PATH_APP_USER_DASHBOARD} element={<PrivateRoute body/>}>
              <Route index element={getSuspense(<UserDashboard/>, true)}/>
            </Route>

            {/*ERROR ROUTES*/}
            <Route path="/404" element={<Error errorCode={404}/>}/>
            <Route path="/400" element={<Error errorCode={400}/>}/>
            <Route path="/403" element={<Error errorCode={403}/>}/>

            <Route path="*" element={<Error errorCode={404}/>}/>

          </Route>
      ))


  return <RouterProvider router={router}/>

}

export default AppRouter;