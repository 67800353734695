const hostname = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;

const getRightBaseUrl = (client: boolean) => {
  if (client) {
    if (hostname === "localhost") {
      return protocol + "//" + hostname + ":" + port
    } else {
      return protocol + "//" + hostname
    }
  } else {
    if (hostname === "localhost") {
      return protocol + "//" + hostname + ":8081"
    } else {
      return protocol + "//auth." + hostname
    }
  }
}

const AuthConfig = {
  authority: String(getRightBaseUrl(false) + process.env.REACT_APP_AUTH_URL),
  client_id: String(process.env.REACT_APP_IDENTITY_CLIENT_ID),
  redirect_uri: String(getRightBaseUrl(true) + process.env.REACT_APP_REDIRECT_URL),
  login: String(getRightBaseUrl(false) + process.env.REACT_APP_AUTH_URL + "/login"),
  automaticSilentRenew: true,
  loadUserInfo: true,
  silent_redirect_uri: String(getRightBaseUrl(true) + process.env.REACT_APP_SILENT_REDIRECT_URL),
  post_logout_redirect_uri: String(getRightBaseUrl(true) + process.env.REACT_APP_LOGOFF_REDIRECT_URL),
  grantType: String("password"),
  scope: String("openid profile frontend_roles"),
  onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname)
}

export default AuthConfig;